import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const OurTeam = () => {
  return (
    <div className="c-our-team" id="team">
      <div className="c-container">
        <h1 className="c-our-team__title">Ons Team</h1>
        <div className="c-our-team__list">
          <div className="c-our-team__list-item">
            <StaticImage
              className="c-our-team__list-item-img"
              placeholder="none"
              src="../../images/luc.jpg"
              height={200}
            />
            <h3 className="c-our-team__list-item-name">Luc Van Quickelberge</h3>
            <p className="c-our-team__list-item-role">
              Co-founder, Sales & Product ontwikkeling
            </p>
          </div>
          
          <div className="c-our-team__list-item">
            <div
              className="c-our-team__list-item-img"
              placeholder="none"
              src="../../images/diederik.jpg"
              height={200}
            >
              <div className="c-our-team__list-item-img-text">?</div>
            </div>
            <h3 className="c-our-team__list-item-name">Jij?</h3>
            <p className="c-our-team__list-item-role">
              Interesse om Qloq en het team te vervoegen? Contacteer ons!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
