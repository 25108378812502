import { window } from "browser-monads";
import scrollTo from "gatsby-plugin-smoothscroll";
import React from "react";
import Integrations from "../integrations/Integrations";
import PricingItem from "./pricingitem";

const Pricing = () => {
  return (
    <div className="c-pricing" id="price">
      <div className="c-container">
        <h1 className="c-pricing__title">Prijs</h1>
        <div className="c-pricing__items">
          <PricingItem
            title="Gratis"
            price={0}
            ctaText="Start met plannen"
            onCtaClick={() => {
              window.location.assign("https://app.qloq.be");
            }}
            ctaTheme="light"
            included={["Max 5 afspraken per week", "1 teamlid"]}
            excluded={[
              "Widget om klanten te laten plannen",
              "Rekening houdend met weersomstandigheden",
            ]}
          />
          <PricingItem
            theme="highlight"
            title="Pro"
            price={22}
            ctaText="Start met plannen"
            onCtaClick={() => window.location.assign("https://app.qloq.be")}
            included={[
              "Oneindig aantal afspraken",
              "Widget om klanten te laten plannen",
              "Tot 5 teamleden",
              "Rekening houdend met weersomstandigheden",
            ]}
            earlyBird={true}
          />
          <PricingItem
            theme="Gratis"
            title="Custom"
            price={null}
            ctaText="Contacteer ons!"
            ctaTheme="light"
            onCtaClick={() => scrollTo("#contact")}
            description={
              <>
                <p>
                  Qloq integreert met de meeste software in de markt. Wij helpen
                  u dus graag op weg om Qloq te doen werken met uw bestaande
                  systemen.
                </p>
                <br></br>
                <p>
                  Nood aan een specifieke oplossing? Laat ons iets weten en dan
                  nemen wij contact met u op. 
                </p>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
