import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { window } from "browser-monads";
import scrollTo from "gatsby-plugin-smoothscroll";

const Header = ({ alert }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window.scrollY > 20) {
      setScrolled(true);
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <header
      className={`c-header ${scrolled ? "c-header--scrolled" : ""} ${
        alert ? "c-header--with-alert" : ""
      }`}
    >
      <div className="c-container">
        <div className="c-header__content">
          <div className="c-header__logo">
            <StaticImage
              src={"../../images/Logo.png"}
              placeholder="none"
              height={60}
              alt="qloq.be logo"
            />
          </div>
          <div className="c-header__menu">
            <ul className="c-header__menu-list">
              <li
                className="c-header__menu-list-item"
                onClick={() => scrollTo("#price")}
              >
                Prijs
              </li>
              <li
                className="c-header__menu-list-item"
                onClick={() => scrollTo("#team")}
              >
                Wie zijn wij?
              </li>
              <li
                className="c-header__menu-list-item"
                onClick={() => scrollTo("#contact")}
              >
                Contact
              </li>
              {/* <li className="c-header__menu-list-item">Registreer</li> */}
              <li
                className="c-header__menu-list-item c-header__menu-list-item--btn"
                onClick={() => scrollTo("#demo")}
              >
                Boek een demo
              </li>
              <li
                className="c-header__menu-list-item c-header__menu-list-item--btn-light"
                onClick={() =>
                  window.location.assign(
                    "https://app.qloq.be/Identity/Account/Login"
                  )
                }
              >
                Login
              </li>
            </ul>
          </div>
        </div>
      </div>
      {alert && <div className="c-header__alert">{alert}</div>}
    </header>
  );
};

export default Header;
