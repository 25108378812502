import React from "react";
import Done from "../../images/done.svg";
import Close from "../../images/close.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import Custom from "../../images/custom.svg";

const PricingItem = ({
  title,
  price,
  ctaText,
  ctaTheme,
  included,
  excluded,
  theme,
  onCtaClick,
  description,
  earlyBird,
}) => {
  return (
    <div
      className={`c-pricing-item ${theme ? `c-pricing-item--${theme}` : ""}`}
    >
      {earlyBird && (
        <div className="c-pricing-item__early-bird">Early bird!</div>
      )}
      <div className="c-pricing-item__title">{title}</div>
      {price != null && (
        <div className="c-pricing-item__price">
          <div className="c-pricing-item__price-euro">€</div>
          <div className="c-pricing-item__price-number">
            {price}
            <div className="c-pricing-item__price-month">/maand</div>
          </div>
        </div>
      )}
      {earlyBird && (
        <div className="c-pricing-item__sentence">
          = levenslang 50% korting op de lijstprijs
        </div>
      )}
      {price == null && (
        <div className="c-pricing-item__price">
          <div className="c-pricing-item__price-text">
            <Custom width={58} />
          </div>
        </div>
      )}
      <div
        onClick={onCtaClick}
        className={`c-pricing-item__cta ${
          ctaTheme ? `c-pricing-item__cta--${ctaTheme}` : ""
        }`}
      >
        {ctaText}
      </div>
      {description && (
        <div className="c-pricing-item__description">{description}</div>
      )}
      <ul className="c-pricing-item__list">
        {included &&
          included.map((i) => (
            <li className="c-pricing-item__list-item">
              <span className="c-pricing-item__list-item-icon">
                <Done style={{ paddingRight: "10px", color: "#38b2b1" }} />
              </span>{" "}
              {i}
            </li>
          ))}
        {excluded &&
          excluded.map((i) => (
            <li className="c-pricing-item__list-item c-pricing-item__list-item--disabled">
              <span className="c-pricing-item__list-item-icon">
                <Close style={{ paddingRight: "10px", width: "30px" }} />
              </span>
              {i}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PricingItem;
