import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Pin from "../../images/pin.svg";
import Email from "../../images/mail.svg";
import Phone from "../../images/phone.svg";
import scrollTo from "gatsby-plugin-smoothscroll";

const Footer = () => {
  return (
    <footer className="c-footer">
      <div className="c-footer__content c-container">
        <div className="c-footer__logo">
          <StaticImage
            src="../../images/qloq_white_big.png"
            placeholder="none"
            quality={100}
          />
          <div className="c-footer__logo-text">Wij regelen uw planning.</div>
        </div>
        <div className="c-footer__contact">
          <div className="c-footer__contact-item">
            <Pin />
            <div className="c-footer__contact-item-text">
              Bissegemstraat 9A
              <br /> 8560 Gullegem
            </div>
          </div>
          <div className="c-footer__contact-item">
            <Email />
            <div className="c-footer__contact-item-text">luc@qloq.be</div>
          </div>
          <div className="c-footer__contact-item">
            <Phone />
            <div className="c-footer__contact-item-text">+32486712656</div>
          </div>
        </div>
        <div className="c-footer__about">
          <h1 className="c-footer__about-title">Over ons</h1>
          <ul className="c-footer__about-list">
            <li className="c-footer__about-item">
              <div
                className="c-footer__about-list-item"
                onClick={() => scrollTo("#features")}
              >
                Features
              </div>
            </li>
            <li className="c-footer__about-item">
              <div
                className="c-footer__about-list-item"
                onClick={() => scrollTo("#price")}
              >
                Pricing
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="c-footer__bottom">Qloq.be All rights reserved ©2021</div>
    </footer>
  );
};

export default Footer;
