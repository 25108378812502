import React from "react";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";

const Calendly = () => {
  return (
    <div className="c-calendly" id="demo">
      <div id="qloqWidget"></div>
      <Helmet>
            {/* <script src="https://app.qloq.be/js/widget-local.js?v=18"></script> */}
            <script src="https://app.qloq.be/js/widget.js?v=19"></script>
            <script>{ `function render() { 
                            console.log("Attempting...");
                            var isReady = typeof initiateWidget == "function";
                            if (isReady) {
                                initiateWidget('216ec7bb-d60b-44a0-9f74-b9362714659c');
                            }
                            else { 
                                setTimeout(render, 100);
                            }
                        }
                render()
                `}</script>
          </Helmet>
    </div>
  );
};

export default Calendly;
