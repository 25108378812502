import React, { useState } from "react";

const Contact = ({ toggleAlert }) => {
  const [voornaam, setVoornaam] = useState();
  const [achternaam, setAchternaam] = useState();
  const [bedrijfsnaam, setBedrijfsnaam] = useState();
  const [telefoonnummer, setTelefoonnummer] = useState();
  const [email, setEmail] = useState();
  const [bericht, setBericht] = useState();
  const [messageSend, setMessageSend] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData(document.getElementById("contact-form"));

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        toggleAlert(
          "Uw aanvraag is succesvol verzonden! We nemen zo snel mogelijk contact met u op."
        )
      )
      .then(() => {
        setVoornaam("");
        setAchternaam("");
        setBedrijfsnaam("");
        setTelefoonnummer("");
        setEmail("");
        setBericht("");
        setMessageSend(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="c-contact" id="contact">
      <div className="c-contact__background">
        <svg width={1000} height={800} viewBox="0 0 1000 700">
          <rect
            fill="#edfafc"
            x={300}
            y={0}
            width={400}
            height={400}
            transform="rotate(45, 70, 70)"
            rx={10}
          ></rect>
        </svg>
      </div>
      <div className="c-container">
        <h1 className="c-contact__title">Contact</h1>
        <form
          className="c-contact__window"
          id="contact-form"
          name="contact"
          data-netlify="true"
          onSubmit={
            messageSend
              ? (ev) => {
                  ev.preventDefault();
                }
              : (ev) => handleSubmit(ev)
          }
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="c-contact__window-row">
            <div className="c-contact__input">
              <input
                type="text"
                className="c-contact__input-field"
                placeholder="Voornaam"
                name="voornaam"
                value={voornaam}
                onChange={(ev) => setVoornaam(ev.target.value)}
              ></input>
            </div>
            <div className="c-contact__input">
              <input
                type="text"
                className="c-contact__input-field"
                placeholder="Achternaam"
                name="achternaam"
                value={achternaam}
                onChange={(ev) => setAchternaam(ev.target.value)}
              ></input>
            </div>
          </div>
          <div className="c-contact__window-row">
            <div className="c-contact__input">
              <input
                type="text"
                className="c-contact__input-field"
                placeholder="Bedrijsnaam"
                name="bedrijfsnaam"
                value={bedrijfsnaam}
                onChange={(ev) => setBedrijfsnaam(ev.target.value)}
              ></input>
            </div>
            <div className="c-contact__input">
              <input
                type="text"
                className="c-contact__input-field"
                placeholder="Gsm-nummer"
                name="gsmnummer"
                value={telefoonnummer}
                onChange={(ev) => setTelefoonnummer(ev.target.value)}
              ></input>
            </div>
          </div>
          <div className="c-contact__window-row">
            <div className="c-contact__input">
              <input
                type="email"
                className="c-contact__input-field"
                placeholder="E-mailaddress"
                name="email"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              ></input>
            </div>
            <div className="c-contact__input"></div>
          </div>
          <div className="c-contact__window-row">
            <div className="c-contact__textfield">
              <textarea
                className="c-contact__input-textarea"
                placeholder="Ik ben geintereseerd in een demo..."
                rows={10}
                name="bericht"
                value={bericht}
                onChange={(ev) => setBericht(ev.target.value)}
              ></textarea>
            </div>
          </div>
          <input
            type="submit"
            className={`c-contact__submit ${
              messageSend ? "c-contact__submit--success" : ""
            }`}
            value={messageSend ? "Verzonden" : "Verzenden"}
          ></input>
        </form>
      </div>
    </div>
  );
};

export default Contact;
