import * as React from "react";
import Calendly from "../components/calendly/calendly";
import Contact from "../components/contact/contact";
import CTA from "../components/cta/cta";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Integrations from "../components/integrations/Integrations";
import Jumbotron from "../components/jumbotron/jumbotron";
import OurTeam from "../components/ourteam/ourteam";
import Pricing from "../components/pricing/pricing";
import { Helmet } from "react-helmet";
import "../shared/styles/site.scss";

// markup
const IndexPage = () => {
  const [alert, setAlert] = React.useState(null);
  const ctas = [
    {
      imagePath: "Point1",
      title:
        "Optimaliseer uw planning door afspraken in te plannen wanneer ze u passen.",
      description: (
        <>
          <p>
            Qloq laat u toe afspraken te bewaren en later in te plannen wanneer
            u dat goed uitkomt.
          </p>
          <br></br>
          <p>
            Qloq houdt ook rekening met de reistijd tussen verschillende
            bestemmingen en bepaalt wanneer u het best naar welke afspraak
            rijdt.
          </p>
        </>
      ),
    },
    {
      imagePath: "Point2",
      title: "Voor zelfstandigen die werken met of zonder team.",
      reverse: true,
      description: (
        <p>
          Met Qloq kunt u met meerdere agenda’s tegelijkertijd werken. En zo
          vindt u snel in uw agenda of die van uw medewerkers het ideale
          tijdslot om uw klanten te helpen.
        </p>
      ),
    },
    {
      imagePath: "Point3",
      title: "Laat klanten zelf afspraken inboeken met onze widget",
      list: [
        "Geef de prijs mee van uw dienst afhankelijk van urgentie",
        "Laat Qloq de ideale planning voor u maken, rekening houdend met reistijd",
        "Krijg nieuwe opdrachten binnen ook als u niet aan de slag bent",
      ],
    },
  ];

  const toggleAlert = (alert) => {
    setAlert(alert);

    setTimeout(() => setAlert(null), 5000);
  };

  return (
    <>
      <Helmet>
        <title>Locatiegebaseerde planning voor professionals</title>
        <meta
          name="description"
          content="Qloq is een platform dat u toe laat uw planning op te maken
        rekening houdend met reistijd en weersomstandigheden. Daarnaast krijgt u ook de mogelijkheid om
        klanten een afspraak in te laten plannen via uw website rechstreeks in uw planning."
        />
        <meta
          name="og:title"
          content="Locatiegebaseerde planningstool voor professionals"
        />
        <meta
          name="og:description"
          content="Optimaliseer uw planning rekening houdend met reistijd en weersomstandigheden."
        />
        <meta name="og:image" content="/qloq_social.jpg" />
        <meta name="og:url" content="https://www.qloq.be" />
      </Helmet>
      <Header alert={alert} />
      <main>
        <Jumbotron />
        {ctas.map((cta) => (
          <CTA
            imagePath={cta.imagePath}
            title={cta.title}
            reverse={cta.reverse}
            description={cta.description}
            list={cta.list}
          />
        ))}
        <Integrations />
        <Pricing />
        <Calendly />
        <OurTeam />
        <Contact toggleAlert={toggleAlert} />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
