import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import QloqGif from "../../images/qloq.gif";
import Close from "../../images/close.svg";

const Jumbotron = () => {
  const [showModal, setShowModal] = useState();

  return (
    <>
      <div className="c-jumbotron">
        <div className="c-container">
          <div className="c-jumbotron__content">
            <div className="c-jumbotron__left">
              <h1 className="c-jumbotron__title">
                Locatiegebaseerde planningstool voor professionals
              </h1>
              <h3 className="c-jumbotron__sub-title">
                Optimaliseer uw planning rekening houdend met reistijd en
                weersomstandigheden
              </h3>
            </div>
            <div
              className="c-jumbotron__right"
              onClick={() => setShowModal(true)}
            >
              <img src={QloqGif} />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="c-jumbotron__modal" onClick={() => setShowModal(false)}>
          <div className className="c-jumbotron__modal-close">
            <Close />
          </div>
          <iframe
            src="https://player.vimeo.com/video/639706189"
            width="640"
            height="564"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </>
  );
};

export default Jumbotron;
