import { useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { graphql } from "gatsby";
import Done from "../../images/done.svg";

const CTA = ({
  children,
  imagePath,
  title,
  description,
  list,
  reverse = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      Point1: file(relativePath: { eq: "Point1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100)
        }
      }
      Point2: file(relativePath: { eq: "Point2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100)
        }
      }
      Point3: file(relativePath: { eq: "Point3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100)
        }
      }
    }
  `);

  const image = getImage(data[imagePath]);

  return (
    <div className="c-container" id="features">
      <div className={`c-cta ${reverse ? "c-cta--reverse" : ""}`}>
        <div className="c-cta__left">
          {title && <h1 className="c-cta__title">{title}</h1>}
          {description && <p className="c-cta__description">{description}</p>}
          {list && (
            <ul className="c-cta__list">
              {list.map((item) => {
                return (
                  <li className="c-cta__list-item">
                    <Done />
                    <div className="c-cta__list-item-text">{item}</div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="c-cta__right">
          <GatsbyImage image={image} />
        </div>
      </div>
    </div>
  );
};

export default CTA;
