import React from "react";
import Outlook from "../../images/outlook.svg";
import Calendar from "../../images/google-calendar.svg";

const Integrations = () => {
  return (
    <div className="c-container c-integrations">
      <h2 className="c-integrations__title">Integreert met</h2>
      <div className="c-integrations__list">
        <Outlook height={50} />
        <Calendar height={50} />
      </div>
    </div>
  );
};

export default Integrations;
